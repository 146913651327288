// Core
import React from 'react';

// Libraries
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

// Plugins
import { formatNumber } from '../../../../../plugins/general';
import {
  lastOperationalEdgeStatus,
  lastOperationalUnitData,
  operationModeToText,
  overallStatusToTagType,
  overallStatusToText,
  permissiveStatusToIndicatorStatus,
} from '../../../../../plugins/crusher';

// Types
import { EquipmentUnitDataCrusher } from '../../../../../types/crusher';
import {
  EdgeDeviceOperationalStatus,
  EdgeDeviceOperationalStatusStatus,
} from '../../../../../types/global';
import { Theme } from '../../../../../types/theme';

// Components
import { AlertLine } from '../../../../../layout/icons';
import { AlertType } from '../../../../../layout/elements/Alert';
import { Button, ButtonType } from '../../../../../layout/elements/Button';
import { Col } from '../../../../../layout/elements/Col';
import { Drawer, DrawerSize } from '../../../../../layout/elements/Drawer';
import {
  Paragraph,
  ParagraphSize,
  ParagraphWeight,
} from '../../../../../layout/elements/Paragraph';
// import { ParamIncrementor } from '../../../../../layout/elements/ParamIncrementor';
import { Row } from '../../../../../layout/elements/Row';
import { Speedometer } from '../../../../../layout/elements/Speedometer';
import { StatusIndicator, StatusIndicatorSize } from '../../../../../layout/elements/StatusIndicator';
// import { SwitchWithText } from '../../../../../layout/elements/SwitchWithText';
import { Tag } from '../../../../../layout/elements/Tag';

// Component
import { HydraulicUnitProps } from './HydraulicUnit.types';
import {
  BorderBottom,
  BowlMovementContainer,
  BowlMovementTitleContainer,
  CSSettingContainer,
  ClampSpeedometerContainer,
  CurrentCssContainer,
  // ExecuteCommandButtonContainer,
  FooterContainer,
  NumberTeethContainer,
  OperationModeContainer,
  OverallContainer,
  OverallStatusContainer,
  PermissiveContainer,
  PermissiveItem,
  PermissiveList,
  PermissiveTitleContainer,
  // StatusBowlAdjustment,
  TankTempContainer,
  // TargetValueContainer,
  TrampSpeedometerContainer,
  // ParamIncrementorContainer,
} from './HydraulicUnit.style';

function HydraulicUnit({
  className,
  isDrawerOpen,
  onCloseDrawer,
  operationalData,
  testId,
}: HydraulicUnitProps): JSX.Element {
  const { t } = useTranslation();
  const theme: Theme = useTheme();

  const lastUnitData: EquipmentUnitDataCrusher | null = lastOperationalUnitData(operationalData);

  const lastEdgeStatus: EdgeDeviceOperationalStatus | null = lastOperationalEdgeStatus(
    operationalData,
  );

  const renderPermissive = (
    permissiveName: string,
    permissive: boolean | null | undefined,
  ): JSX.Element => (
    <PermissiveItem
      status={permissiveStatusToIndicatorStatus(
        permissive,
        lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
      )}
    >
      <StatusIndicator
        text={permissiveName}
        status={permissiveStatusToIndicatorStatus(
          permissive,
          lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
        )}
      />
    </PermissiveItem>
  );

  const renderBowlMovementPermissive = (
    permissiveName: string,
    permissive: boolean | null | undefined,
  ): JSX.Element => (
    <PermissiveItem
      status={permissiveStatusToIndicatorStatus(
        permissive,
        lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
      )}
    >
      <StatusIndicator
        text={t(`view.crusher.hydraulic.drawer.${permissiveName}`)}
        status={
          permissiveStatusToIndicatorStatus(
            permissive,
            lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
          )
        }
      />
    </PermissiveItem>
  );

  return (
    <Drawer
      testId={testId}
      className={className}
      title={t('view.crusher.hydraulic.drawer.title')}
      open={isDrawerOpen}
      onClose={onCloseDrawer}
      footer={false}
      size={DrawerSize.lg}
      alert={
        lastEdgeStatus?.edgeStatus !== EdgeDeviceOperationalStatusStatus.on
        && {
          type: AlertType.warning,
          icon: <AlertLine />,
          description: `${t('layout.elements.drawer.alertText')}`,
        }
      }
    >
      <OverallContainer>
        {/* CS Settings & Bowl Adjustment */}
        <BorderBottom>
          <Row>
            <Col
              cols={24} // @TODO: Change this to 12 when add controls
            >
              <CSSettingContainer>
                <Row>
                  <Paragraph weight={ParagraphWeight.bold}>
                    {t('view.crusher.csSetting')}
                  </Paragraph>
                </Row>
                <Row>
                  <Col>
                    <CurrentCssContainer>
                      <Paragraph
                        size={ParagraphSize.sm}
                        weight={ParagraphWeight.bold}
                      >
                        {t('view.crusher.hydraulic.drawer.currentCss')}
                      </Paragraph>
                      <Paragraph
                        size={ParagraphSize.lg}
                        weight={ParagraphWeight.bold}
                      >
                        {`${formatNumber(lastUnitData?.csSetting, 2)} mm`}
                      </Paragraph>
                    </CurrentCssContainer>
                  </Col>
                </Row>
                {/* @TODO: back this flow when add control */}
                {/* <TargetValueContainer> */}
                {/*  <Paragraph */}
                {/*    size={ParagraphSize.sm} */}
                {/*    weight={ParagraphWeight.bold} */}
                {/*  > */}
                {/*    {t('view.crusher.hydraulic.drawer.targetValue')} */}
                {/*  </Paragraph> */}
                {/*  <ParamIncrementorContainer> */}
                {/*    <ParamIncrementor */}
                {/*      testId="param-incrementor-hydraulic-unit" */}
                {/*      decimalPlaces={2} */}
                {/*      unit="mm" */}
                {/*      value={data.csSetting} */}
                {/*      maxValue={5} */}
                {/*      minValue={0} */}
                {/*      onChange={handleParamChange} */}
                {/*      incrementStep={0.1} */}
                {/*      block */}
                {/*    /> */}
                {/*  </ParamIncrementorContainer> */}
                {/* </TargetValueContainer> */}
                {/* <Button */}
                {/*  disabled */}
                {/*  onClick={() => alert('Execute command was clicked!')} */}
                {/* > */}
                {/*  {t('common.executeCommand')} */}
                {/* </Button> */}
              </CSSettingContainer>
            </Col>
            {/* <Col cols={12}> */}
            {/*  <BowlAdjustmentContainer> */}
            {/*    <Paragraph weight={ParagraphWeight.bold}> */}
            {/*      {t('view.crusher.hydraulic.drawer.bowlAdjustment')} */}
            {/*    </Paragraph> */}
            {/*    <StatusBowlAdjustment> */}
            {/*      <Paragraph */}
            {/*        size={ParagraphSize.sm} */}
            {/*        weight={ParagraphWeight.bold} */}
            {/*      > */}
            {/*        {t('common.status')} */}
            {/*      </Paragraph> */}
            {/*      <SwitchWithText */}
            {/*        testId="status-switch-hydraulic-unit" */}
            {/*        leftText={`${t('common.close')}`} */}
            {/*        rightText={`${t('common.open')}`} */}
            {/*        onChange={handleSwitchChange} */}
            {/*        checked */}
            {/*      /> */}
            {/*    </StatusBowlAdjustment> */}
            {/*    <ExecuteCommandButtonContainer> */}
            {/*      <Button */}
            {/*        disabled */}
            {/*        onClick={() => alert('Execute command was clicked!')} */}
            {/*      > */}
            {/*        {t('common.executeCommand')} */}
            {/*      </Button> */}
            {/*    </ExecuteCommandButtonContainer> */}
            {/*  </BowlAdjustmentContainer> */}
            {/* </Col> */}
          </Row>
        </BorderBottom>
        {/* Overall Status & Tank Temp */}
        <BorderBottom>
          <Row>
            <Col cols={12}>
              <OverallStatusContainer>
                <Paragraph
                  size={ParagraphSize.sm}
                  weight={ParagraphWeight.bold}
                >
                  {t('view.crusher.overallStatus')}
                </Paragraph>
                <Tag
                  text={overallStatusToText(lastUnitData?.hydraulicOverallStatus)}
                  type={
                    overallStatusToTagType(
                      lastUnitData?.hydraulicOverallStatus,
                      lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                    )
                  }
                />
              </OverallStatusContainer>
            </Col>
            <Col cols={12}>
              <TankTempContainer>
                <Paragraph
                  size={ParagraphSize.sm}
                  weight={ParagraphWeight.bold}
                >
                  {t('view.crusher.hydraulic.tankTemp')}
                </Paragraph>
                <Tag
                  text={overallStatusToText(lastUnitData?.hydraulicTankTempStatus)}
                  type={
                    overallStatusToTagType(
                      lastUnitData?.hydraulicTankTempStatus,
                      lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                    )
                  }
                />
              </TankTempContainer>
            </Col>
          </Row>
        </BorderBottom>
        {/* Clamp & Tramp Pressure */}
        <BorderBottom>
          <Row>
            <Col cols={12}>
              <ClampSpeedometerContainer>
                <Speedometer
                  title={`${t('view.crusher.hydraulic.clampPressure')}`}
                  unit="psi"
                  running={lastEdgeStatus?.edgeStatus === EdgeDeviceOperationalStatusStatus.on}
                  minValue={0}
                  maxValue={3625}
                  value={lastUnitData?.hydraulicClampPressure}
                  thresholds={{ lowAlarm: 2400, highAlarm: 3100 }}
                />
              </ClampSpeedometerContainer>
            </Col>
            <Col cols={12}>
              <TrampSpeedometerContainer>
                <Speedometer
                  title={`${t('view.crusher.hydraulic.trampPressure')}`}
                  unit="psi"
                  running={lastEdgeStatus?.edgeStatus === EdgeDeviceOperationalStatusStatus.on}
                  minValue={0}
                  maxValue={3625}
                  value={lastUnitData?.hydraulicTrampPressure}
                  thresholds={{ lowAlarm: 1900 }}
                />
              </TrampSpeedometerContainer>
            </Col>
          </Row>
        </BorderBottom>
        {/* Number of Teeth & Operation Mode */}
        <BorderBottom>
          <Row>
            <Col cols={12}>
              <NumberTeethContainer>
                <Paragraph
                  weight={ParagraphWeight.bold}
                  color={theme.color.crusherDashboard.hydraulicUnitDrawer.numberTeethTitleTextColor}
                >
                  {t('view.crusher.hydraulic.drawer.teethTitle')}
                </Paragraph>
                <Paragraph
                  size={ParagraphSize.sm}
                  weight={ParagraphWeight.medium}
                  color={theme.color.crusherDashboard.hydraulicUnitDrawer.numberTeethValueTextColor}
                >
                  {formatNumber(lastUnitData?.numberOfTeeth)}
                </Paragraph>
              </NumberTeethContainer>
            </Col>
            <Col cols={12}>
              <OperationModeContainer>
                <Paragraph
                  color={
                    theme.color.crusherDashboard.hydraulicUnitDrawer.operationModelTitleTextColor
                  }
                  weight={ParagraphWeight.bold}
                >
                  {t('view.crusher.hydraulic.drawer.operationModeTitle')}
                </Paragraph>
                <Paragraph
                  size={ParagraphSize.sm}
                  weight={ParagraphWeight.medium}
                  color={
                    theme.color.crusherDashboard.hydraulicUnitDrawer.operationModelStatusTextColor
                  }
                >
                  {operationModeToText(
                    lastUnitData?.operationModeLocalRemote,
                    lastUnitData?.operationModeOperateService,
                  )}
                </Paragraph>
              </OperationModeContainer>
            </Col>
          </Row>
        </BorderBottom>
        {/* Permissives & Bowl Movement */}
        <Row>
          <Col cols={12}>
            <PermissiveContainer>
              <Row>
                <PermissiveTitleContainer>
                  <StatusIndicator
                    size={StatusIndicatorSize.lg}
                    text={t('view.crusher.hydraulic.drawer.permissive')}
                    status={
                      permissiveStatusToIndicatorStatus(
                        lastUnitData?.hydraulicPermissive,
                        lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                      )
                    }
                  />
                </PermissiveTitleContainer>
              </Row>
              <PermissiveList>
                {renderPermissive(
                  t('view.crusher.hydraulic.drawer.pumpOverPressure'),
                  lastUnitData?.pumpOverPressure,
                )}
                {renderPermissive(
                  t('view.crusher.hydraulic.drawer.highOilTemp'),
                  lastUnitData?.highOilTemp,
                )}
                {renderPermissive(
                  t('view.crusher.hydraulic.drawer.hydPumpRunning'),
                  lastUnitData?.hydPumpRunningOver1Hour,
                )}
                {renderPermissive(
                  t('view.crusher.hydraulic.drawer.eStop'),
                  lastUnitData?.eStop,
                )}
                {renderPermissive(
                  t('view.crusher.hydraulic.drawer.systemControlPower'),
                  lastUnitData?.systemControlPower,
                )}
                {renderPermissive(
                  t('view.crusher.hydraulic.drawer.oilLevelLow'),
                  lastUnitData?.oilLevelLow,
                )}
              </PermissiveList>
            </PermissiveContainer>
          </Col>
          <Col cols={12}>
            <BowlMovementContainer>
              <Row>
                <BowlMovementTitleContainer>
                  <Paragraph
                    size={ParagraphSize.md}
                    weight={ParagraphWeight.bold}
                  >
                    {t('view.crusher.hydraulic.drawer.bowlMovementTitle')}
                  </Paragraph>
                </BowlMovementTitleContainer>
              </Row>
              <PermissiveList>
                {renderBowlMovementPermissive(
                  'bowlMovementSensorProx1',
                  lastUnitData?.bowlMovementSensorProx1,
                )}
                {renderBowlMovementPermissive(
                  'bowlMovementSensorProx2',
                  lastUnitData?.bowlMovementSensorProx2,
                )}
              </PermissiveList>
            </BowlMovementContainer>
          </Col>
        </Row>
      </OverallContainer>
      <Row>
        <FooterContainer>
          <Paragraph weight={ParagraphWeight.bold}>
            {t('view.crusher.hydraulic.drawer.solenoidChart')}
          </Paragraph>
          <Button
            type={ButtonType.secondary}
            disabled
            block={false}
            onClick={() => alert('Open the chart button was clicked!')}
          >
            {t('view.crusher.hydraulic.drawer.openTheChart')}
          </Button>
        </FooterContainer>
      </Row>
    </Drawer>
  );
}

export { HydraulicUnit };
