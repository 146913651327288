// Core
import styled from 'styled-components';

export const Element = styled.div`
  /* *************** BACKGROUND COLOR *************** */
  background-color: ${({ theme }) => theme.color.moduleHeader.backgroundColor};
  
  padding: 16px 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  max-width: 100%;

  /* *************** FOCUS *************** */
  :focus {
    outline: none;
  };
`;

export const IconContainer = styled.div`
  /* *************** BACKGROUND COLOR *************** */
  background-color: ${({ theme }) => theme.color.moduleHeader.buttonBackgroundColor};
  padding: 2px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: none;
  height: 20px;
  width: 22px;

  > svg {
    width: 16px;
    height: 16px;
    /* *************** ICON COLOR *************** */
    color: ${({ theme }) => theme.color.moduleHeader.iconColor};
    fill: ${({ theme }) => theme.color.moduleHeader.iconColor};
  };
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const TitleContainer = styled.div`
  flex: 1;
  min-width: 0;
  display: flex;
  align-items: center;
`;

export const ContentContainer = styled.div`
  max-width: 100%;
  overflow: hidden;
`;
