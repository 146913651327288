// Core
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

// Libraries
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';

// Store
import {
  setAuthToken,
  setCustomer,
  setUser,
} from '../../store/session.store';

// Types
import { PositionSideNavigation, SideNavigationProps } from './SideNavigation.types';

// Components
import { ArrowLeft, LogoutBox } from '../icons';
import {
  Button,
  ButtonColor,
  ButtonSize,
  ButtonType,
} from '../elements/Button';
import {
  Paragraph,
  ParagraphSize,
  ParagraphWeight,
} from '../elements/Paragraph';
import { Tooltip, TooltipPlacement } from '../elements/Tooltip';

// Component
import {
  Container,
  ContentContainer,
  FooterContainer,
  HeaderContainer,
  MenuContainer,
  TitleContainer,
} from './SideNavigation.style';

function SideNavigation({
  className,
  footer = 'default',
  header = 'default',
  items,
  side = PositionSideNavigation.left,
  testId,
  title,
}: SideNavigationProps) {
  // Dependencies
  const { t } = useTranslation();
  const navigate: NavigateFunction = useNavigate();

  // Refs
  const contentRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  /* ***********************************************************************************************
  ************************************** INITIAL STATES ********************************************
  *********************************************************************************************** */

  interface State {
    isTooltipVisible: boolean;
  }

  /* Initial state */
  const initialState: State = {
    isTooltipVisible: false,
  };

  const [state, setState] = useState<State>(initialState);

  /* ***********************************************************************************************
  ****************************************** METHODS ***********************************************
  *********************************************************************************************** */

  // Function of logout
  const logoutUser = (): void => {
    setAuthToken(null);
    setUser(null);
    setCustomer(null);
    navigate('/');
  };

  const checkVisibility = useCallback((): void => {
    const content: HTMLDivElement | null = contentRef.current;
    const container: HTMLDivElement | null = containerRef.current;

    if (content && container) {
      setState({
        isTooltipVisible: content.offsetWidth >= container.offsetWidth,
      });
    }
  }, []);

  /* ***********************************************************************************************
   *************************************** COMPONENT HANDLING **************************************
  *********************************************************************************************** */

  /* Rendered the Header based on the [header] prop */
  let renderedHeader: React.ReactNode = 'default';

  if (header === 'default') {
    renderedHeader = (
      <HeaderContainer side={side}>
        <Button
          onClick={() => navigate('/')}
          type={ButtonType.link}
          size={ButtonSize.xs}
          block={false}
          color={ButtonColor.neutral}
        >
          <ArrowLeft />
          {t('common.back')}
        </Button>
      </HeaderContainer>
    );
  } else if (header === false) {
    renderedHeader = null;
  } else {
    renderedHeader = header;
  }

  /* Rendered the Footer based on the [footer] prop */
  let renderedFooter: React.ReactNode = 'default';

  if (footer === 'default') {
    renderedFooter = (
      <FooterContainer side={side}>
        <Button
          onClick={logoutUser}
          type={ButtonType.link}
          size={ButtonSize.xs}
          block={false}
          color={ButtonColor.neutral}
        >
          <LogoutBox />
          {t('common.logout')}
        </Button>
      </FooterContainer>
    );
  } else if (footer === false) {
    renderedFooter = null;
  } else {
    renderedFooter = footer;
  }

  /* Check visibility on mount and resize */
  useEffect((): () => void => {
    const handleVisibilityCheck = (): NodeJS.Timeout => setTimeout(checkVisibility, 0);

    handleVisibilityCheck();
    window.addEventListener('resize', handleVisibilityCheck);

    return () => window.removeEventListener('resize', handleVisibilityCheck);
  }, [checkVisibility, title]);

  return (
    <Container
      className={className}
      data-testid={testId}
      side={side}
    >
      {renderedHeader}
      <TitleContainer side={side} ref={containerRef}>
        {state.isTooltipVisible ? (
          <Tooltip text={title} placement={TooltipPlacement.top}>
            <ContentContainer ref={contentRef}>
              <Paragraph
                size={ParagraphSize.xl}
                weight={ParagraphWeight.bold}
                preventOverflow
              >
                {title}
              </Paragraph>
            </ContentContainer>
          </Tooltip>
        ) : (
          <ContentContainer ref={contentRef}>
            <Paragraph
              size={ParagraphSize.xl}
              weight={ParagraphWeight.bold}
              preventOverflow
            >
              {title}
            </Paragraph>
          </ContentContainer>
        )}
      </TitleContainer>
      <MenuContainer side={side} items={items} />
      {renderedFooter}
    </Container>
  );
}

export { SideNavigation };
