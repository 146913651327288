// Libraries
import { TimelineItemProps } from '../layout/elements/TimelineItem';

/* *************************************************************************************************
 ******************************************** ENUMS ************************************************
 ************************************************************************************************ */

export enum EdgeDeviceOperationalStatusStatus {
  on = 'on',
  off = 'off',
}

export enum EquipmentCategory {
  crusher = 'crusher',
  roll = 'roll',
  screen = 'screen',
  washer = 'washer',
}

export enum EquipmentUnitInstallStatus {
  pre = 'pre',
  installed = 'installed',
  removed = 'removed',
}

export enum UserType {
  admin = 'admin',
  customer = 'customer',
}

export enum TimeUnitType {
  millisecond = 'millisecond',
  second = 'second',
  minute = 'minute',
  hour = 'hour',
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year',
}

/* *************************************************************************************************
 ****************************************** INTERFACES *********************************************
 ************************************************************************************************ */

// ********** CUSTOMER ********** //

export interface Customer {
  createdAt: number;
  db: string;
  domains: string[];
  id: string;
  inactivatedAt: number | null;
  inactivatedBy: string | null;
  name: string;
  status: string;
}

export interface CustomerStats {
  alarms: number | null;
  equipmentUnits: number | null;
  sites: number | null;
  users: number | null;
}

// ********** EQUIPMENT ********** //

export interface EquipmentAlarmThresholds {
  highAlarm?: number;
  highWarning?: number;
  lowAlarm?: number;
  lowWarning?: number;
}

export interface EquipmentModelParameter {
  name: string;
  value: string | number;
}

export interface EquipmentModel {
  alarmThresholds: EquipmentAlarmThresholds[];
  commercialName: string;
  createdAt: number;
  createdBy: string;
  deletedAt?: number | null;
  deletedBy?: string | null;
  equipmentCategory: EquipmentCategory;
  id: string;
  parameters: EquipmentModelParameter[];
  superiorPartNumber: string;
  updatedAt?: number | null;
  updatedBy?: string | null;
}

// ********** EQUIPMENT UNIT ********** //

export interface EquipmentUnit {
  createdAt: number;
  createdBy: string;
  customerPartNumber: string;
  deletedAt: number | null;
  deletedBy: string | null;
  equipmentCategory: EquipmentCategory;
  id: string;
  idArea: string | null;
  idModel: string;
  idSite: string | null;
  installStatus: EquipmentUnitInstallStatus;
  installedAt: number | null;
  installedBy: string | null;
  serialNumber: string;
  updatedAt: number;
  updatedBy: string;
}

export interface EquipmentUnitDataParamConfig {
  minValue: number;
  maxValue: number;
  thresholds: EquipmentAlarmThresholds;
}

// ********** SITE ********** //

export interface Site {
  createdAt: number;
  createdBy: string;
  deletedAt: number | null;
  deletedBy: string | null;
  id: string;
  identification: string;
  imageUrl: string | null;
  lat: number;
  lng: number;
  name: string;
  superiorCode: string;
  updatedAt: number;
  updatedBy: string;
}

// ********** EDGE DEVICE ********** //

export interface EdgeDeviceOperationalStatus {
  timestamp: number;
  edgeStatus: EdgeDeviceOperationalStatusStatus;
}

// ********** USER ********** //

export interface User {
  email: string;
  generatedAt: number;
  id: string;
  name: string;
  type: UserType;
}

// ********** LAYOUT ********** //

export interface BreakpointSize {
  xxs: string;
  xs: string;
  sm: string;
  md: string;
  lg: string;
}

export interface GridBreakpointConfig {
  cols?: number;
  offset?: number;
}

export interface GridBreakpointsGutter {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
}

/* *************************************************************************************************
 ****************************************** LAYOUT *************************************************
 ************************************************************************************************ */

/* Equipment Model Create */

export interface EquipmentModelCreateProps {
  testId?: string;
  category?: string;
  isDrawerOpen: boolean;
  onCloseDrawer: () => void;
  onChange: (field: string, value: string | string[]) => void;
  equipmentModel: Record<string, any>;
  onCreate: () => void;
}

/* Light Bulb */

export interface LightBulbProps {
  testId?: string;
  timelineItems?: TimelineItemProps[];
}

export interface LightbulbDetailsModuleProps {
  testId?: string;
  lightbulbModel?: string;
  lastUpdateLightbulb: number;
  detailsModuleData?: any;
  installedAt: number;
  serialNumber?: string | number;
  firmwareVersion?: string | number;
  lastUpdateEdgeDevice: number;
}

export interface LightbulbControllersModuleProps {
  testId?: string;
}

export interface LightbulbMonitoringModuleProps {
  testId?: string;
}

export interface LightbulbCameraModuleProps {
  testId?: string;
}

export interface LightbulbTimelineModuleProps {
  testId?: string;
  timelineItems?: TimelineItemProps[];
}
