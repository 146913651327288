import {
  EdgeDeviceOperationalStatus,
  EquipmentCategory,
  EquipmentUnitInstallStatus,
} from './global';

/* ***************************************** ENUMS ********************************************** */

export enum CrusherFeederAutomationMode {
  manual = 'manual',
  auto = 'auto',
}

export enum CrusherMotorControl {
  manual = 'manual',
  auto = 'auto',
}

export enum CrusherOperationMode {
  local = 'local',
  remote = 'remote',
  service = 'service',
}

export enum CrusherOverallStatus {
  good = 'good',
  warning = 'warning',
  alarm = 'alarm',
}

export enum CrusherRunningStatus {
  starting = 'starting',
  running = 'running',
  fault = 'fault',
  off = 'off',
}

export enum CrusherWeatherModeStatus {
  on = 'on',
  off = 'off',
}

export enum CrusherDashboardChartRange {
  fiveMinutes = 'fiveMinutes',
  week = 'week',
  month = 'month',
}

export enum CrusherWebsocketRequestEvents {
  realTimeCrusherData = 'realTimeCrusherData',
}

export enum CrusherWebsocketResponseEvents {
  equipmentUnitCrusherData = 'equipmentUnitCrusherData',
}

/* *************************************** TRENDINGS ******************************************** */

export enum TrendingType {
  motorCurrent = 'motorCurrent',
  pid = 'pid',
  lubeUnit = 'lubeUnit',
  hydraulic = 'hydraulic',
  ringBounceSensor = 'ringBounceSensor',
}

/* *************************************** INTERFACES ******************************************* */

export interface EquipmentUnitCrusher {
  createdAt: number;
  createdBy: string;
  customerPartNumber: string;
  deletedAt?: number | null;
  deletedBy?: string | null;
  equipmentCategory: EquipmentCategory;
  id: string;
  idArea?: string | null;
  idModel: string;
  idSite: string;
  installStatus: EquipmentUnitInstallStatus;
  installedAt: number;
  installedBy: string;
  serialNumber: string;
  updatedAt?: number | null;
  updatedBy?: string | null;
}

export interface EquipmentUnitDataCrusher {
  // *** EquipmentUnitData *** //
  idEquipmentUnit: string;
  equipmentCategory: EquipmentCategory;
  timestamp: number | null;
  unitStatus: CrusherRunningStatus | null;

  // *** EquipmentUnitDataCrusher *** //
  bowlCloseRequest: boolean | null;
  bowlMovementSensorProx1: boolean | null;
  bowlMovementSensorProx2: boolean | null;
  bowlOpenRequest: boolean | null;
  calibrationActive: boolean | null;
  cavityLevel: number | null;
  cavityLevelTransducerFault: boolean | null;
  clampPressureSensorFault: boolean | null;
  coolerFanStatus: CrusherRunningStatus | null;
  countershaftBlowerRunningStatus: boolean | null;
  countershaftBushingTemp: number | null;
  countershaftBushingTransducerFault: boolean | null;
  countershaftEccentricTemp: number | null;
  crusherAboveMinimumCSS: boolean | null;
  crusherBeltSlip: boolean | null;
  crusherBowlCreep: boolean | null;
  crusherMotor: CrusherRunningStatus | null;
  crusherMotor1Fault: boolean | null;
  crusherMotor1RunHours: number | null;
  crusherMotor2Fault: boolean | null;
  crusherMotorAmps: number | null;
  crusherMotorOverallStatus: CrusherOverallStatus | null;
  crusherMotorRpm: number | null;
  crusherOperationMode: boolean | null;
  crusherStartPermissives: boolean | null;
  csSetting: number | null;
  dischargeRunHours: number | null;
  dischargeRunningStatus: CrusherRunningStatus | null;
  eStop: boolean | null;
  feederAutomation: CrusherFeederAutomationMode | null;
  feederRunHours: number | null;
  feederRunningStatus: CrusherRunningStatus | null;
  feederPermissives: boolean | null;
  feederStarterFault: boolean | null;
  highCavityLevel: boolean | null;
  highCounterShaftBushingTemp: boolean | null;
  highCrusherCurrentPermissive: boolean | null;
  highEccentricBushingTemp: boolean | null;
  highOilTemp: boolean | null;
  highVibrationPermissive: boolean | null;
  hydExcessiveClampPressure: boolean | null;
  hydExcessiveTrampPressure: boolean | null;
  hydLowClampPressure: boolean | null;
  hydLowTrampPressure: boolean | null;
  hydPumpRunningOver1Hour: boolean | null;
  hydraulicClampPressure: number | null;
  hydraulicOverallStatus: CrusherOverallStatus | null;
  hydraulicPermissive: boolean | null;
  hydraulicPumpRunningStatus: CrusherRunningStatus | null;
  hydraulicTankTempStatus: CrusherOverallStatus | null;
  hydraulicTrampPressure: number | null;
  linerWear: number | null;
  lowLubeOil: boolean | null;
  lowLubeOilPressure: boolean | null;
  lubeDifferentialTempPermissive: boolean | null;
  lubeHeaterPermissive: boolean | null;
  lubeHeaterStarterFault: boolean | null;
  lubeHighReturnTempPermissive: boolean | null;
  lubeOilLevelLow: boolean | null;
  lubeOilPressure: boolean | null;
  lubePermissive: boolean | null;
  lubePressureTransducerFault: boolean | null;
  lubePump1RunHours: number | null;
  lubePump1StarterFault: boolean | null;
  lubePumpRunningStatus: CrusherRunningStatus | null;
  lubePumpStarterFault: boolean | null;
  lubeReturnLowTemp: boolean | null;
  lubeReturnTempHigh: boolean | null;
  lubeReturnTempTransducerFault: boolean | null;
  lubeSupplyDifferential: number | null;
  lubeTankHeaterEnable: boolean | null;
  lubeTankLevel: boolean | null;
  lubeTankOilLevel: boolean | null;
  lubeTankTempTransducerFault: boolean | null;
  lubricationOilPressure: number | null;
  lubricationOverallStatus: CrusherOverallStatus | null;
  lubricationReturnTemperature: number | null;
  lubricationSupplyTemperature: number | null;
  lubricationTankTemperature: number | null;
  minCrusherRunTimer: boolean | null;
  minimumLubeRunTimer: boolean | null;
  motorControl: CrusherMotorControl | null;
  numberOfTeeth: number | null;
  oilLevelLow: boolean | null;
  operationModeLocalRemote: CrusherOperationMode | null;
  operationModeOperateService: CrusherOperationMode | null;
  pumpOverPressure: boolean | null;
  systemControlPower: boolean | null;
  tankLubeHeaterStatus: CrusherRunningStatus | null;
  tankTempTooLowToRun: boolean | null;
  tankTempTooLowToStart: boolean | null;
  trampPressureSensorFault: boolean | null;
  vibrationSensor1: number | null;
  vibrationSensor2: number | null;
  vibrationSensor3: number | null;
  // vibrationSensor4: number | null; // @TODO Validate the 4th vibration sensor after first release
  weatherModeStatus: CrusherWeatherModeStatus | null;
}

export type CrusherOperationalData = (
  EquipmentUnitDataCrusher | EdgeDeviceOperationalStatus
)[];
