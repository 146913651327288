// Core
import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

// Libraries
import { useTranslation } from 'react-i18next';

// Types
import { EquipmentCategory } from '../../../../types/global';

// Locale
import i18n from '../../../../locale/i18n';

// Plugins
import { RequestMethod, requestVantageApi } from '../../../../plugins/request';

// Components
import { Col } from '../../../../layout/elements/Col';
import { Drawer } from '../../../../layout/elements/Drawer';
// import { FileUpload } from '../../../../layout/elements/FileUpload';
import { Input, InputType } from '../../../../layout/elements/Input';
import { Paragraph, ParagraphWeight } from '../../../../layout/elements/Paragraph';
import { Row } from '../../../../layout/elements/Row';
import { showToast, ToastType } from '../../../../layout/elements/Toast';

// View
import { EquipmentModelCreateProps } from './EquipmentModelCreate.types';
import { SectionContainer } from './EquipmentModelCreate.style';

function EquipmentModelCreate({
  isDrawerOpen,
  onCloseDrawer,
  testId,
  updateEquipmentModels,
}: EquipmentModelCreateProps): JSX.Element | null {
  // Dependencies
  const { equipmentCategory } = useParams<{ equipmentCategory: EquipmentCategory }>();
  const { t } = useTranslation();

  // Interfaces
  interface LocalParameter {
    name: string;
    value: string | number | null;
    unit: string | null;
  }

  interface EquipmentDataState {
    commercialName?: string;
    superiorPartNumber?: string;
    parameters: LocalParameter[];
  }

  interface State {
    equipmentData: EquipmentDataState;
    isCreationInProgress: true | null | number;
  }

  // Parameters initialization
  const parametersByCategory = useMemo(() => ({
    [EquipmentCategory.crusher]: [
      { name: 'headDiameter', unit: 'mm' },
      { name: 'weight', unit: 'kg' },
      { name: 'recommendedHP', unit: 'HP' },
      { name: 'maxFeedOpening', unit: 'mm' },
      { name: 'csS', unit: 'mm' },
    ],
    [EquipmentCategory.roll]: [],
    [EquipmentCategory.screen]: [],
    [EquipmentCategory.washer]: [],
  }), []);

  const initializeParameters = useCallback(
    (category: EquipmentCategory) => (
      parametersByCategory[category].map((param) => ({ ...param, value: null }))
    ),
    [parametersByCategory],
  );

  // Initial state
  const initialState: State = {
    equipmentData: {
      commercialName: undefined,
      superiorPartNumber: undefined,
      parameters: initializeParameters(equipmentCategory || EquipmentCategory.crusher),
    },
    isCreationInProgress: null,
  };

  const [state, setState] = useState<State>(initialState);

  /* *********************************************************************************************
  ******************************************* METHODS ********************************************
  * ******************************************************************************************* */

  const startDrawerLoading = (): void => {
    setState((prevState: State): State => ({
      ...prevState,
      isCreationInProgress: true,
    }));
  };

  const stopDrawerLoading = (): void => {
    setState((prevState: State): State => ({
      ...prevState,
      isCreationInProgress: 100,
    }));
  };

  // Handlers
  const handleClose = useCallback((): void => {
    onCloseDrawer();
    setState({
      ...state,
      equipmentData: {
        commercialName: undefined,
        superiorPartNumber: undefined,
        parameters: initializeParameters(equipmentCategory || EquipmentCategory.crusher),
      },
      isCreationInProgress: null,
    });
  }, [
    onCloseDrawer,
    initializeParameters,
    equipmentCategory,
    state,
  ]);

  const handleCreate = async (newModel: EquipmentDataState): Promise<void> => {
    setState((prevState) => ({ ...prevState, isCreationInProgress: true }));
    startDrawerLoading();

    try {
      await requestVantageApi.equipmentModel({
        method: RequestMethod.POST,
        path: `/${equipmentCategory}`,
        body: newModel,
      });
      updateEquipmentModels();
      showToast({
        text: i18n.t(
          'view.equipmentModel.create.toastEquipmentCreated',
          { newModel: state.equipmentData.superiorPartNumber },
        ),
        type: ToastType.success,
      });
    } catch (error: unknown) {
      console.error('Failed to create equipment model', error);
    }

    stopDrawerLoading();
    setTimeout((): void => {
      handleClose();
    }, 300);
  };

  const handleInputChange = useCallback((
    field: keyof EquipmentDataState,
    value: string | number,
  ): void => {
    setState((prevState) => ({
      ...prevState,
      equipmentData: {
        ...prevState.equipmentData,
        [field]: value,
      },
    }));
  }, []);

  const handleParamChange = useCallback((paramName: string, value: string | number): void => {
    setState((prevState) => ({
      ...prevState,
      equipmentData: {
        ...prevState.equipmentData,
        parameters: prevState.equipmentData.parameters.map((param) => (
          param.name === paramName
            ? { ...param, value }
            : param
        )),
      },
    }));
  }, []);

  /* *********************************************************************************************
  ************************************* COMPONENT HANDLING ***************************************
  * ******************************************************************************************** */

  const isCreateButtonDisabled: boolean = useMemo(() => {
    const { equipmentData } = state;
    const isMainInfoIncomplete: boolean = (
      !equipmentData.commercialName || !equipmentData.superiorPartNumber
    );
    const areParametersIncomplete: boolean = (
      equipmentData.parameters.some((param) => param.value === null)
    );

    return isMainInfoIncomplete || areParametersIncomplete;
  }, [state]);

  const renderParameters = useCallback((category: EquipmentCategory): JSX.Element[] => {
    const parameters: { name: string, unit: string }[] = parametersByCategory[category];

    return parameters.map((param) => (
      <Row key={param.name}>
        <Col>
          <Input
            onChange={(e: any) => handleParamChange(param.name, e)}
            label={`${i18n.t(`equipment.${category}.parameters.${param.name}`)}`}
            type={InputType.number}
            placeholder={
              `${i18n.t(
                'view.equipmentModel.create.inputPlaceholder',
                { parameter: i18n.t(`equipment.${category}.parameters.${param.name}`) },
              )}`
            }
            testId={`input-${param.name}`}
            suffix={param.unit}
            value={state.equipmentData.parameters.find((p) => p.name === param.name)?.value}
          />
        </Col>
      </Row>
    ));
  }, [
    state.equipmentData.parameters,
    handleParamChange,
    parametersByCategory,
  ]);

  return (
    !equipmentCategory
      ? null
      : (
        <Drawer
          testId={testId}
          open={isDrawerOpen}
          title={
            t(
              'view.equipmentModel.create.titleDrawer',
              { model: t(`common.equipmentCategory.${equipmentCategory}.singular`) },
            )
          }
          onClose={handleClose}
          primaryAction={{
            onClick: () => handleCreate(state.equipmentData),
            children: t('common.create'),
            disabled: isCreateButtonDisabled,
            loading: state.isCreationInProgress,
            loadingText: `${t('common.creating')}`,
          }}
          secondaryAction={{ onClick: handleClose, children: t('common.cancel') }}
        >
          <Row>
            <Col>
              {/* Uncomment when backend accepts image upload */}
              {/* <SectionContainer> */}
              {/*  <Row> */}
              {/*    <Col> */}
              {/*      <Paragraph weight={ParagraphWeight.bold}>{t('common.image')}</Paragraph> */}
              {/*    </Col> */}
              {/*  </Row> */}
              {/*  <Row> */}
              {/*    <Col> */}
              {/*      <FileUpload */}
              {/*        onUpload={() => {}} */}
              {/*        file={{ source: undefined, fileName: undefined }} */}
              {/*        id="equipment-model-create" */}
              {/*        onRemove={() => {}} */}
              {/*        disabled */}
              {/*        supportingText=".jpg .png .gif .jpeg (Max 1MB)" */}
              {/*      /> */}
              {/*    </Col> */}
              {/*  </Row> */}
              {/* </SectionContainer> */}
              <SectionContainer>
                <Row>
                  <Col>
                    <Paragraph weight={ParagraphWeight.bold}>{t('common.mainInfo')}</Paragraph>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      onChange={
                        (e: string | number | null | undefined) => {
                          handleInputChange('commercialName', e as string);
                        }
                      }
                      label={`${t('common.commercialName')}`}
                      maxLength={65}
                      placeholder={
                        `${i18n.t(
                          'view.equipmentModel.create.inputPlaceholder',
                          { parameter: t('common.commercialName') },
                        )}`
                      }
                      testId="input-commercialName"
                      value={state.equipmentData.commercialName}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      onChange={
                        (e: string | number | null | undefined) => {
                          handleInputChange('superiorPartNumber', e as string);
                        }
                      }
                      label={`${t('common.superiorPartNumber')}`}
                      maxLength={65}
                      placeholder={
                        `${i18n.t(
                          'view.equipmentModel.create.inputPlaceholder',
                          { parameter: t('common.superiorPartNumber') },
                        )}`
                      }
                      testId="input-superiorPartNumber"
                      value={state.equipmentData.superiorPartNumber}
                    />
                  </Col>
                </Row>
              </SectionContainer>
              <SectionContainer>
                <Row>
                  <Col>
                    <Paragraph weight={ParagraphWeight.bold}>{t('common.parameters')}</Paragraph>
                  </Col>
                </Row>
                {renderParameters(equipmentCategory)}
              </SectionContainer>
            </Col>
          </Row>
        </Drawer>
      )
  );
}

export { EquipmentModelCreate };
