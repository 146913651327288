/* eslint-disable @typescript-eslint/indent */

/* Note: please be careful with indentation, we have the rule disabled because of
*  eslint not being able to properly indent js inside styled components
*/
// Core
import styled from 'styled-components';

// Libraries
import { Menu as MenuAntD } from 'antd';

// Component
import { SideNavigationProps, PositionSideNavigation } from './SideNavigation.types';

/* ************** HEADER ******************* */

export const HeaderContainer = styled.div<Pick<SideNavigationProps, 'side'>>`
  height: 8%;
  display: flex;
  padding: 24px 24px 32px 20px;
  width: 100%;
  align-items: center;
`;

/* ************** COMPONENT ******************* */

export const Container = styled.div<Pick<SideNavigationProps, 'side'>>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-right: ${({ side, theme }) => (
    side === PositionSideNavigation.left
      ? `1px solid ${theme.color.sideNavigation.borderColor}`
      : 'none'
  )};
  border-left:${({ side, theme }) => (
    side === PositionSideNavigation.right
      ? `1px solid ${theme.color.sideNavigation.borderColor}`
      : 'none'
  )};
`;

/* ************** TITLE ******************* */

export const TitleContainer = styled.div<Pick<SideNavigationProps, 'side'>>`
  flex: 1;
  min-width: 0;
  display: flex;
  align-items: center;
  height: 8%;
`;

export const ContentContainer = styled.div`
  max-width: 100%;
  overflow: hidden;
  padding: 0 24px 32px 40px;
`;

/* ************** MENU ******************* */

export const MenuContainer = styled(MenuAntD)<Pick<SideNavigationProps, 'side'>>`
  height: 76%;
  padding: 0 24px 0 24px;
  border-inline-end: none !important;
  padding-inline: 0;
    
  /* ************** TITLE ******************* */
    
  &.ant-menu .ant-menu-item-group-title {
    padding: 0 0 8px 40px;
    font-family: ${({ theme }) => theme.font.bold};
    font-size: 12px;
    color: ${({ theme }) => theme.color.sideNavigation.listTitleTextColor};
    transition: color 0.3s;
  };

  /* ************** ITEMS ******************* */
    
  &.ant-menu .ant-menu-item-group-list {
    padding: 0 20px 32px 24px;
    font-family: ${({ theme }) => theme.font.bold};
    color: ${({ theme }) => theme.color.sideNavigation.itemTextColor};
    transition: color 0.3s;
  }; 
    
  &.ant-menu .ant-menu-item-group .ant-menu-item-group-list .ant-menu-item {
    padding-inline: 16px 0;
    margin-inline: 0;
    border-radius: 4px;
  };

  &.ant-menu .ant-menu-title-content {
    font-family: ${({ theme }) => theme.font.bold};
    font-size: 16px;
  };

  /* ************** ITEMS HOVERED ******************* */
    
  &.ant-menu-light .ant-menu-item:not(.ant-menu-item-selected):hover {
    color: ${({ theme }) => theme.color.sideNavigation.hoverTextColor};
    background-color: ${({ theme }) => theme.color.sideNavigation.hoverBackgroundColor};
    transition: color 0.3s, background-color 0.3s;
    border-radius: 4px;
  };

  /* ************** ITEMS SELECTED ******************* */
    
  &.ant-menu-light .ant-menu-item-selected {
    color: ${({ theme }) => theme.color.sideNavigation.focusedTextColor};
    background-color: ${({ theme }) => theme.color.sideNavigation.focusedBackgroundColor};
    transition: color 0.3s, background-color 0.3s;
    border-radius: 4px;
  };
`;

/* ************** FOOTER ******************* */

export const FooterContainer = styled.div<Pick<SideNavigationProps, 'side'>>`
  height: 8%;
  display: flex;
  padding: 32px 24px 40px 20px;
  width: 100%;
  align-items: center;
`;
