// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Store
import { getUser } from '../../../store/session.store';

// Types
import { User, UserType } from '../../../types/global';

// Components
import { Col } from '../../../layout/elements/Col';
import {
  SideNavigation,
  SideNavigationProps,
  TypeSideNavigation,
} from '../../../layout/SideNavigation';

// Module
import { SettingsModuleProps } from './SettingsModule.types';

function SettingsModule({
  className,
  testId,
}: SettingsModuleProps): JSX.Element | null {
  // Dependencies
  const { t } = useTranslation();

  // Getter
  const user: User | null = getUser();

  if (!user) {
    return null;
  }

  const items: Pick<SideNavigationProps, 'items'>['items'] = user.type === UserType.admin
    ? [
      {
        key: 'account-settings',
        label: `${t('view.settings.settingsModule.accountSectionTitle').toUpperCase()}`,
        type: TypeSideNavigation.group,
        children: [
          {
            key: 'profile-settings',
            label: `${t('view.settings.settingsModule.menuItemProfile')}`,
          },
        ],
      },
    ] : [];

  return (
    <Col cols={4} className={className} testId={testId}>
      <SideNavigation
        title={`${t('common.settings')}`}
        items={items}
      />
    </Col>
  );
}

export { SettingsModule };
