// Core
import React from 'react';

export enum PositionSideNavigation {
  left = 'left',
  right = 'right',
}

export enum TypeSideNavigation {
  group = 'group',
}

export interface SideNavigationProps {
  className?: string;
  footer?: React.ReactNode | false | 'default';
  header?: React.ReactNode | false | 'default';
  items: {
    key: string;
    label: string;
    type: TypeSideNavigation;
    children: {
      key: string;
      label: string;
    }[];
  }[];
  side?: PositionSideNavigation;
  testId?: string;
  title: string;
}
