// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Types
import {
  EdgeDeviceOperationalStatus,
  EdgeDeviceOperationalStatusStatus,
} from '../../../../../types/global';
import { EquipmentUnitDataCrusher } from '../../../../../types/crusher';

// Components
import { AlertType } from '../../../../../layout/elements/Alert';
import { Col } from '../../../../../layout/elements/Col';
import { Drawer, DrawerSize } from '../../../../../layout/elements/Drawer';
import {
  Paragraph,
  ParagraphSize,
  ParagraphWeight,
} from '../../../../../layout/elements/Paragraph';
import { Row } from '../../../../../layout/elements/Row';
import {
  StatusIndicator,
  StatusIndicatorSize,
} from '../../../../../layout/elements/StatusIndicator';
import { AlertLine } from '../../../../../layout/icons';

// Plugins
import {
  lastOperationalEdgeStatus,
  lastOperationalUnitData,
  permissiveStatusToIndicatorStatus,
} from '../../../../../plugins/crusher';

// View
import { FeederInterlockProps } from './FeederInterlock.types';
import {
  CrusherContainer,
  CrusherPermissives,
  CrusherStartListDescription,
  FeederInterlockContainer,
  FeederStartContainer,
  GeneralContainer,
  GeneralPermissives,
  HydraulicsContainer,
  HydraulicsPermissives,
  LubricationContainer,
  LubricationPermissives,
} from './FeederInterlock.style';

function FeederInterlock({
  className,
  isDrawerOpen,
  onCloseDrawer,
  operationalData,
  testId,
}: FeederInterlockProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  const lastUnitData: EquipmentUnitDataCrusher | null = lastOperationalUnitData(operationalData);

  const lastEdgeStatus: EdgeDeviceOperationalStatus | null = lastOperationalEdgeStatus(
    operationalData,
  );

  const renderPermissiveItem = (
    permissiveName: string,
    permissiveValue: boolean | null | undefined,
  ): JSX.Element => (
    <Col>
      <CrusherStartListDescription
        status={
          permissiveStatusToIndicatorStatus(
            permissiveValue,
            lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
          )
        }
      >
        <StatusIndicator
          className="list-text-description"
          text={t(`view.crusher.feederInterlock.drawer.${permissiveName}`)}
          status={
            permissiveStatusToIndicatorStatus(
              permissiveValue,
              lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
            )
          }
        />
      </CrusherStartListDescription>
    </Col>
  );

  return (
    <Drawer
      testId={testId}
      className={className}
      title={t('view.crusher.feederInterlock.title')}
      open={isDrawerOpen}
      onClose={onCloseDrawer}
      footer={false}
      alert={
        lastEdgeStatus?.edgeStatus !== EdgeDeviceOperationalStatusStatus.on
        && {
          type: AlertType.warning,
          icon: <AlertLine />,
          description: `${t('layout.elements.drawer.alertText')}`,
        }
      }
      size={DrawerSize.lg}
    >
      <FeederInterlockContainer>
        <Row>
          <FeederStartContainer>
            <Col>
              <StatusIndicator
                text={t('view.crusher.feederInterlock.drawer.feederStartPermissive')}
                status={
                  permissiveStatusToIndicatorStatus(
                    lastUnitData?.feederPermissives,
                    lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                  )
                }
                size={StatusIndicatorSize.lg}
              />
            </Col>
          </FeederStartContainer>
        </Row>

        <Row>
          <Col cols={12}>
            <div>
              <GeneralContainer>
                <Col>
                  <Paragraph size={ParagraphSize.lg} weight={ParagraphWeight.bold}>
                    {t('view.crusher.feederInterlock.general')}
                  </Paragraph>
                </Col>
              </GeneralContainer>

              <GeneralPermissives>
                {renderPermissiveItem('eStop', lastUnitData?.lubeOilLevelLow)}
                {renderPermissiveItem('crusherBeltSlip', lastUnitData?.crusherBeltSlip)}
                {renderPermissiveItem('feederStarterFault', lastUnitData?.feederStarterFault)}
                {renderPermissiveItem(
                  'calibrationActive',
                  lastUnitData?.calibrationActive,
                )}
                {renderPermissiveItem('crusherMotor2Fault', lastUnitData?.crusherMotor2Fault)}
              </GeneralPermissives>

              <Col>
                <CrusherContainer>
                  <Paragraph size={ParagraphSize.lg} weight={ParagraphWeight.bold}>
                    {t('view.crusher.feederInterlock.crusher')}
                  </Paragraph>
                </CrusherContainer>

                <CrusherPermissives>
                  {renderPermissiveItem(
                    'trampPressureSensorFault',
                    lastUnitData?.trampPressureSensorFault,
                  )}
                  {renderPermissiveItem(
                    'clampPressureSensorFault',
                    lastUnitData?.clampPressureSensorFault,
                  )}
                  {renderPermissiveItem(
                    'highEccentricBushingTemp',
                    lastUnitData?.highEccentricBushingTemp,
                  )}
                  {renderPermissiveItem(
                    'highCounterShaftBushingTemp',
                    lastUnitData?.highCounterShaftBushingTemp,
                  )}
                  {renderPermissiveItem('highCavityLevel', lastUnitData?.highCavityLevel)}
                  {renderPermissiveItem(
                    'highVibrationPermissive',
                    lastUnitData?.highVibrationPermissive,
                  )}
                  {renderPermissiveItem('crusherBowlCreep', lastUnitData?.crusherBowlCreep)}
                  {renderPermissiveItem('minCrusherRunTimer', lastUnitData?.minCrusherRunTimer)}
                  {renderPermissiveItem(
                    'highCrusherCurrentPermissive',
                    lastUnitData?.highCrusherCurrentPermissive,
                  )}
                </CrusherPermissives>

              </Col>
            </div>
          </Col>

          <Col cols={12}>
            <div>
              <HydraulicsContainer>
                <Col>
                  <Paragraph size={ParagraphSize.lg} weight={ParagraphWeight.bold}>
                    {t('view.crusher.feederInterlock.hydraulic')}
                  </Paragraph>
                </Col>
              </HydraulicsContainer>

              <HydraulicsPermissives>
                {renderPermissiveItem(
                  'systemControlPower',
                  lastUnitData?.systemControlPower,
                )}
                {renderPermissiveItem(
                  'crusherOperationMode',
                  lastUnitData?.crusherOperationMode,
                )}
                {renderPermissiveItem('oilLevelLow', lastUnitData?.oilLevelLow)}
                {renderPermissiveItem('hydLowClampPressure', lastUnitData?.hydLowClampPressure)}
                {renderPermissiveItem('hydLowTrampPressure', lastUnitData?.hydLowTrampPressure)}
                {renderPermissiveItem(
                  'hydExcessiveTrampPressure',
                  lastUnitData?.hydExcessiveTrampPressure,
                )}
                {renderPermissiveItem(
                  'hydExcessiveClampPressure',
                  lastUnitData?.hydExcessiveClampPressure,
                )}
                {renderPermissiveItem('highOilTemp', lastUnitData?.highOilTemp)}
                {renderPermissiveItem('bowlOpenRequest', lastUnitData?.bowlOpenRequest)}
                {renderPermissiveItem('bowlCloseRequest', lastUnitData?.bowlCloseRequest)}
              </HydraulicsPermissives>
            </div>

            <Col>
              <LubricationContainer>
                <Paragraph size={ParagraphSize.lg} weight={ParagraphWeight.bold}>
                  {t('view.crusher.feederInterlock.lubrication')}
                </Paragraph>
              </LubricationContainer>

              <LubricationPermissives>
                {renderPermissiveItem('lubePumpStarterFault', lastUnitData?.lubePumpStarterFault)}
                {renderPermissiveItem('lowLubeOilPressure', lastUnitData?.lowLubeOilPressure)}
                {renderPermissiveItem(
                  'lubeDifferentialTempPermissive',
                  lastUnitData?.lubeDifferentialTempPermissive,
                )}
                {renderPermissiveItem(
                  'lubeHighReturnTempPermissive',
                  lastUnitData?.lubeHighReturnTempPermissive,
                )}
              </LubricationPermissives>
            </Col>
          </Col>
        </Row>
      </FeederInterlockContainer>
    </Drawer>
  );
}

export { FeederInterlock };
