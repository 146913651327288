// Core
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

// Components
import { Tooltip, TooltipPlacement } from '../Tooltip';
import { Paragraph, ParagraphWeight } from '../Paragraph';
import { CaretRight } from '../../icons';

// Component
import {
  Container,
  ContentContainer,
  Element,
  IconContainer,
  TitleContainer,
} from './ModuleHeader.style';
import { ModuleHeaderProps } from './ModuleHeader.types';

function ModuleHeader({
  className,
  onClick,
  testId,
  title,
}: ModuleHeaderProps): JSX.Element {
  const [isTooltipVisible, setIsToolTipVisible] = useState<boolean>(false);

  // Refs
  const contentRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const checkVisibility = useCallback((): void => {
    const content: HTMLDivElement | null = contentRef.current;
    const container: HTMLDivElement | null = containerRef.current;

    if (content && container) {
      const contentWidth: number = content.scrollWidth;
      const containerMaxWidth: number = container.scrollWidth;
      if (contentWidth >= containerMaxWidth || 0) {
        setIsToolTipVisible(true);
      } else {
        setIsToolTipVisible(false);
      }
    }
  }, []);

  // Check visibility on mount and resize
  useEffect((): () => void => {
    const handleVisibilityCheck = (): void => {
      setTimeout(checkVisibility, 0);
    };
    handleVisibilityCheck();
    window.addEventListener('resize', handleVisibilityCheck);
    return (): void => {
      window.removeEventListener('resize', handleVisibilityCheck);
    };
  }, [checkVisibility, title]);

  return (
    <Container data-testid={testId} className={className}>
      <Element tabIndex={0} onClick={onClick} role="button">
        <TitleContainer ref={containerRef}>
          {isTooltipVisible ? (
            <Tooltip text={title} placement={TooltipPlacement.top}>
              <ContentContainer ref={contentRef}>
                <Paragraph
                  weight={ParagraphWeight.bold}
                  preventOverflow
                >
                  {title}
                </Paragraph>
              </ContentContainer>
            </Tooltip>
          ) : (
            <ContentContainer ref={contentRef}>
              <Paragraph
                weight={ParagraphWeight.bold}
                preventOverflow
              >
                {title}
              </Paragraph>
            </ContentContainer>
          )}
        </TitleContainer>
        <IconContainer>
          <CaretRight />
        </IconContainer>
      </Element>
    </Container>
  );
}

export { ModuleHeader };
