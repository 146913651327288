// Core
import styled from 'styled-components';

// Components
import { Col } from '../../layout/elements/Col';
import { Row } from '../../layout/elements/Row';

export const Container = styled.div`
  height: 100vh;
`;

export const Content = styled(Row)`
  height: 100%;  
`;

export const PanelContainer = styled(Col)`
  background-color: ${({ theme }) => theme.color.settings.panelBackgroundColor};
`;

export const PageHeaderContainer = styled.div`
  padding: 40px;
`;

export const RegisteredTrademark = styled.sup`
  font-size: 13px;
`;
