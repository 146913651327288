import { ApplicationThemes, AntDApplicationThemes } from '../types/theme';

// Plugins
import { hexToRgba } from '../plugins/general';

/* *************************************************************************************************
**************************************** DEFAULT COLORS ********************************************
************************************************************************************************* */

export const defaultColors = {
  // Focus
  colorFocus10: '#E6F6FF',
  colorFocus20: '#04A5FD',

  // Error
  colorError10: '#FAE5E5',
  colorError20: '#F5CCCC',
  colorError30: '#F0B3B3',
  colorError40: '#EB9999',
  colorError50: '#E06666',
  colorError60: '#D63333',
  colorError70: '#CC0000',
  colorError80: '#A30000',
  colorError90: '#7A0000',
  colorError100: '#520000',
  colorError110: '#3D0000',
  colorError120: '#290000',

  // Neutral
  colorNeutral0: '#FFFFFF',
  colorNeutral10: '#F9F9F9',
  colorNeutral20: '#F5F6F7',
  colorNeutral30: '#EBECEF',
  colorNeutral40: '#E1E3E6',
  colorNeutral50: '#D7DADE',
  colorNeutral60: '#C3C7CE',
  colorNeutral70: '#AFB5BD',
  colorNeutral80: '#9BA2AE',
  colorNeutral90: '#868C96',
  colorNeutral100: '#717680',
  colorNeutral110: '#5B6169',
  colorNeutral120: '#51565E',
  colorNeutral130: '#464B53',
  colorNeutral140: '#3C4047',
  colorNeutral150: '#31353C',
  colorNeutral160: '#08101E',

  // Success
  colorSuccess10: '#DDF1E6',
  colorSuccess20: '#CCF3DF',
  colorSuccess30: '#99E8C0',
  colorSuccess40: '#66DCA0',
  colorSuccess50: '#33D181',
  colorSuccess60: '#1CAA56',
  colorSuccess70: '#009E4E',
  colorSuccess80: '#00763A',
  colorSuccess90: '#004F27',
  colorSuccess100: '#002713',

  // Primary
  colorPrimary10: '#FCF5F3',
  colorPrimary20: '#FAECE8',
  colorPrimary30: '#F7DBD2',
  colorPrimary40: '#F2C7BC',
  colorPrimary50: '#EDB5A5',
  colorPrimary60: '#DC6847',
  colorPrimary70: '#D3461E',
  colorPrimary80: '#7F2A11',

  // Alert
  colorAlert10: '#FFF9E6',
  colorAlert20: '#FFF3CD',
  colorAlert30: '#FEEDB4',
  colorAlert40: '#FEE79B',
  colorAlert50: '#FEDA68',
  colorAlert60: '#FDCE36',
  colorAlert70: '#FCC206',
  colorAlert80: '#CA9B03',
  colorAlert90: '#987402',
  colorAlert100: '#654E02',
  colorAlert110: '#4C3A01',
  colorAlert120: '#332701',
  colorAlert130: '#191300',
  colorAlert140: '#000000',

  // Chart
  colorChartVector15: '#4B1D8F',
  colorChartVector16: '#FDCE36',
  colorChartVector17: '#F49300',
  colorChartVector18: '#0095AE',
  colorChartVector19: '#D3461E',
  colorChartVector20: '#020AA6',
  colorChartVector21: '#C0219B',
  colorChartVector22: '#9BDCBB',
  colorChartVector23: '#E85E95',
  colorChartVector24: '#005D9C',
};

const defaultFonts = {
  regular: 'HelveticaNeueRegular, sans-serif',
  medium: 'HelveticaNeueMedium, sans-serif',
  bold: 'HelveticaNeueBold, sans-serif',
};

/* *************************************************************************************************
**************************************** THEME *****************************************************
************************************************************************************************* */

const themes: ApplicationThemes = {
  default: {
    font: defaultFonts,
    color: {
      alert: {
        errorBackground: defaultColors.colorError10,
        errorBorder: defaultColors.colorError50,
        errorPrimaryAction: defaultColors.colorError70,
        errorSecondaryAction: defaultColors.colorError70,
        errorText: defaultColors.colorError70,
        successBackground: defaultColors.colorSuccess10,
        successBorder: defaultColors.colorSuccess70,
        successPrimaryAction: defaultColors.colorSuccess80,
        successSecondaryAction: defaultColors.colorSuccess80,
        successText: defaultColors.colorSuccess80,
        warningBackground: defaultColors.colorAlert20,
        warningBorder: defaultColors.colorAlert90,
        warningSecondaryAction: defaultColors.colorAlert90,
        warningText: defaultColors.colorAlert90,
      },
      adminActions: {
        adminActionBackgroundColor: defaultColors.colorNeutral20,
        borderColor: defaultColors.colorNeutral40,
        containerBackgroundColor: defaultColors.colorNeutral0,
      },
      app: {
        windowDimensionsBgColor: defaultColors.colorNeutral160,
        windowDimensionsColor: defaultColors.colorNeutral0,
      },
      barChart: {
        axisColor: defaultColors.colorNeutral90,
        seriesColorBlue: defaultColors.colorChartVector20,
        seriesColorGreen: defaultColors.colorChartVector18,
        seriesColorLightBlue: defaultColors.colorChartVector24,
        seriesColorLightGreen: defaultColors.colorChartVector22,
        seriesColorLightOrange: defaultColors.colorChartVector17,
        seriesColorLightPink: defaultColors.colorChartVector23,
        seriesColorOrange: defaultColors.colorChartVector19,
        seriesColorPink: defaultColors.colorChartVector21,
        seriesColorPurple: defaultColors.colorChartVector15,
        seriesColorYellow: defaultColors.colorChartVector16,
        unitColor: defaultColors.colorNeutral90,
      },
      breadcrumb: {
        targetTextColor: defaultColors.colorNeutral100,
        targetTextHoverColor: defaultColors.colorPrimary70,
        lastTargetPlantTextColor: defaultColors.colorPrimary70,
        lastTargetPageTextColor: defaultColors.colorNeutral140,
      },
      button: {
        primaryType: {
          primaryColor: {
            backgroundColor: defaultColors.colorPrimary70,
            textColor: defaultColors.colorNeutral0,
            hoverTextColor: defaultColors.colorNeutral0,
            disabledTextColor: defaultColors.colorNeutral70,
            hoverBackgroundColor: defaultColors.colorPrimary60,
            hoverBorderColor: defaultColors.colorPrimary60,
            borderColor: defaultColors.colorPrimary70,
            disabledBorderColor: defaultColors.colorNeutral30,
            disabledBackgroundColor: defaultColors.colorNeutral30,
          },
          dangerColor: {
            backgroundColor: defaultColors.colorError70,
            borderColor: defaultColors.colorError70,
            textColor: defaultColors.colorNeutral0,
            hoverTextColor: defaultColors.colorNeutral0,
            disabledTextColor: defaultColors.colorError30,
            disabledBackgroundColor: defaultColors.colorError10,
            disabledBorderColor: defaultColors.colorError10,
            hoverBackgroundColor: defaultColors.colorError60,
            hoverBorderColor: defaultColors.colorError60,
          },
        },
        secondaryType: {
          primaryColor: {
            backgroundColor: defaultColors.colorNeutral0,
            textColor: defaultColors.colorNeutral120,
            disabledTextColor: defaultColors.colorNeutral70,
            hoverBackgroundColor: defaultColors.colorPrimary70,
            hoverBorderColor: defaultColors.colorPrimary70,
            hoverTextColor: defaultColors.colorNeutral0,
            borderColor: defaultColors.colorNeutral50,
            disabledBorderColor: defaultColors.colorNeutral60,
            disabledBackgroundColor: defaultColors.colorNeutral10,
          },
        },
        linkType: {
          primaryColor: {
            textColor: defaultColors.colorPrimary70,
            hoverTextColor: defaultColors.colorPrimary60,
            disabledTextColor: defaultColors.colorNeutral70,
          },
          dangerColor: {
            textColor: defaultColors.colorError70,
            hoverTextColor: defaultColors.colorError50,
            disabledTextColor: defaultColors.colorError20,
          },
          neutralColor: {
            textColor: defaultColors.colorNeutral140,
            hoverTextColor: defaultColors.colorPrimary70,
            disabledTextColor: defaultColors.colorNeutral70,
          },
        },
        loadBackgroundColor: defaultColors.colorPrimary80,
        buttonBoxShadowColor: defaultColors.colorNeutral30,
      },
      cameraFeed: {
        actionsIconHoverColor: defaultColors.colorNeutral0,
        backgroundGradientColor: defaultColors.colorNeutral150,
        buttonBackgroundColor: defaultColors.colorNeutral150,
        buttonOfflineBgColor: hexToRgba(defaultColors.colorNeutral50, 0.15),
        cameraFeedBackgroundColor: defaultColors.colorNeutral160,
        fullScreenIconColor: defaultColors.colorNeutral80,
        iconActiveColor: defaultColors.colorNeutral0,
        iconDisabledColor: defaultColors.colorNeutral80,
        liveIndicatorColor: defaultColors.colorError70,
        switchActiveBackgroundColor: defaultColors.colorPrimary70,
        textColor: defaultColors.colorNeutral0,
      },
      cardCustomer: {
        hoverShadowColor: defaultColors.colorFocus20,
        hoverBorderColor: defaultColors.colorFocus20,
        backgroundColor: defaultColors.colorNeutral0,
        borderColor: defaultColors.colorNeutral40,
        imageBackgroundColor: defaultColors.colorNeutral0,
        metricsColor: defaultColors.colorNeutral110,
        metricsBorderColor: defaultColors.colorNeutral50,
      },
      cardEquipment: {
        hoverShadowColor: defaultColors.colorFocus20,
        hoverBorderColor: defaultColors.colorFocus20,
        backgroundColor: defaultColors.colorNeutral0,
        borderColor: defaultColors.colorNeutral40,
        imageBackgroundColor: defaultColors.colorNeutral20,
      },
      checkbox: {
        borderColor: defaultColors.colorNeutral60,
        borderColorHover: defaultColors.colorNeutral80,
        disabledBackgroundColor: defaultColors.colorNeutral20,
        disabledBorderColor: defaultColors.colorNeutral50,
        checkedBackgroundColor: defaultColors.colorPrimary70,
        checkedIconColor: defaultColors.colorNeutral0,
        disabledCheckedIconColor: defaultColors.colorNeutral60,
        hoverBackgroundColor: defaultColors.colorNeutral20,
        hoverCheckedBackgroundColor: defaultColors.colorPrimary60,
        supportingTextColor: defaultColors.colorNeutral100,
        supportingTextHoverColor: defaultColors.colorNeutral120,
        disabledSupportingTextColor: defaultColors.colorNeutral60,
      },
      connectionStatus: {
        onlineIconColor: defaultColors.colorSuccess60,
        offlineIconColor: defaultColors.colorNeutral100,
        statusTextColor: defaultColors.colorNeutral120,
      },
      crusherDashboard: {
        borderColor: defaultColors.colorNeutral40,
        crusherDashboardBackgroundColor: defaultColors.colorNeutral20,
        csSettings: {
          unitTextColor: defaultColors.colorNeutral100,
        },
        detailsModule: {
          activeAlarmBackgroundColor: defaultColors.colorError70,
          activeAlarmTextColor: defaultColors.colorNeutral0,
          breadcrumbTextColor: defaultColors.colorPrimary70,
          lastUpdateTextColor: defaultColors.colorNeutral90,
          lastUpdateTimeTextColor: defaultColors.colorNeutral140,
          parametersTextColor: defaultColors.colorNeutral80,
          valuesTextColor: defaultColors.colorNeutral130,
        },
        feederInterlock: {
          borderColor: defaultColors.colorNeutral40,
          descriptionListErrorBackgroundColor: defaultColors.colorError10,
        },
        lineWear: {
          unitTextColor: defaultColors.colorNeutral100,
        },
        hydraulicUnitDrawer: {
          borderColor: defaultColors.colorNeutral40,
          bowlAdjustmentTextColor: defaultColors.colorNeutral160,
          csSettingTextColor: defaultColors.colorNeutral160,
          descriptionListErrorBackgroundColor: defaultColors.colorError10,
          descriptionListErrorTextColor: defaultColors.colorError70,
          descriptionListTextColor: defaultColors.colorNeutral130,
          hydraulicFooterBackgroundColor: defaultColors.colorNeutral10,
          listTextColor: defaultColors.colorNeutral100,
          numberTeethValueTextColor: defaultColors.colorNeutral140,
          numberTeethTitleTextColor: defaultColors.colorNeutral160,
          operationModelStatusTextColor: defaultColors.colorNeutral140,
          operationModelTitleTextColor: defaultColors.colorNeutral160,
          overallStatusTextColor: defaultColors.colorNeutral160,
          tankTemperatureTextColor: defaultColors.colorNeutral160,
          titleListTextColor: defaultColors.colorNeutral160,
        },
        lubricationUnitDrawer: {
          borderColor: defaultColors.colorNeutral40,
          coolerFanTextColor: defaultColors.colorNeutral160,
          descriptionListErrorBackgroundColor: defaultColors.colorError10,
          descriptionListErrorTextColor: defaultColors.colorError70,
          descriptionListTextColor: defaultColors.colorNeutral130,
          overallStatusDescriptionTextColor: defaultColors.colorNeutral160,
          titleListTextColor: defaultColors.colorNeutral160,
          winterModeTextColor: defaultColors.colorNeutral160,
        },
        statusTitleColor: defaultColors.colorNeutral100,
        modulesBackgroundColor: defaultColors.colorNeutral0,
        motorControlDrawer: {
          borderColor: defaultColors.colorNeutral40,
          controllersTitlesTextColor: defaultColors.colorNeutral160,
          lubeTankTempTextColor: defaultColors.colorNeutral130,
          monitoringControlModeTextColor: defaultColors.colorNeutral130,
          monitoringHoursTextColor: defaultColors.colorNeutral140,
          monitoringMotorControlTextColor: defaultColors.colorNeutral130,
          monitoringRunningListTextColor: defaultColors.colorNeutral130,
        },
        motorStatus: {
          borderColor: defaultColors.colorNeutral40,
          counterShaftBushingStatusTextColor: defaultColors.colorNeutral160,
          crusherMotorStatusListTextColor: defaultColors.colorNeutral130,
          crusherMotorStatusListTitleTextColor: defaultColors.colorNeutral130,
          descriptionListErrorBackgroundColor: defaultColors.colorError10,
          descriptionListErrorTextColor: defaultColors.colorError70,
          footerBackgroundColor: defaultColors.colorNeutral10,
          overallStatusTextColor: defaultColors.colorNeutral160,
          ringVibrationTextColor: defaultColors.colorNeutral160,
          sensorListTextColor: defaultColors.colorNeutral130,
          sensorListValuePercentageTextColor: defaultColors.colorNeutral100,
          titleThermometerTextColor: defaultColors.colorNeutral120,
        },
        trendings: {
          lastUpdateTextColor: defaultColors.colorNeutral90,
          lastUpdateStrongTextColor: defaultColors.colorNeutral140,
        },
        vibrationModule: {
          itemsBorderColor: defaultColors.colorNeutral40,
          nameTextColor: defaultColors.colorNeutral130,
          valueTextColor: defaultColors.colorNeutral100,
        },
      },
      columnLayoutTable: {
        columnLayoutBackgroundHover: defaultColors.colorPrimary20,
        columnLayoutSelectBackground: defaultColors.colorNeutral0,
        columnLayoutSelectBoxShadow: defaultColors.colorNeutral40,
        columnLayoutSelectBorder: defaultColors.colorNeutral20,
        columnLayoutTextColor: defaultColors.colorNeutral110,
        columnLayoutHoverColor: defaultColors.colorPrimary70,
        columnLayoutOptionColor: defaultColors.colorPrimary70,
      },
      customersScreen: {
        customerOverviewBackgroundColor: defaultColors.colorNeutral20,
        customerInfoTextColor: defaultColors.colorNeutral120,
      },
      customersUpdate: {
        borderColor: defaultColors.colorNeutral40,
      },
      dashboardIndicator: {
        activeIconColor: defaultColors.colorError70,
        activeTitleTextColor: defaultColors.colorError70,
        activeValueTextColor: defaultColors.colorError70,
        backgroundColor: defaultColors.colorNeutral0,
        boxShadowColor: defaultColors.colorNeutral150,
        iconColor: defaultColors.colorNeutral100,
        informationIconColor: defaultColors.colorNeutral90,
        titleTextColor: defaultColors.colorNeutral100,
        valueTextColor: defaultColors.colorNeutral140,
      },
      deleteWarningBox: {
        backgroundColor: defaultColors.colorError10,
        borderColor: defaultColors.colorError50,
        containerIconColor: defaultColors.colorError70,
        deleteItemTextColor: defaultColors.colorError80,
        textColor: defaultColors.colorError70,
      },
      drawer: {
        closeButtonColor: defaultColors.colorNeutral90,
        closeButtonHoverColor: defaultColors.colorPrimary60,
        colorGradient: defaultColors.colorNeutral0,
        colorIcon: defaultColors.colorNeutral140,
        colorTitle: defaultColors.colorNeutral150,
        drawerCloseButtonBackgroundColor: defaultColors.colorNeutral20,
        imageBorderColor: defaultColors.colorNeutral40,
        imageBackgroundColor: defaultColors.colorNeutral0,
        scrollBackgroundColor: defaultColors.colorNeutral50,
      },
      emptyBox: {
        backgroundColor: defaultColors.colorNeutral10,
        descriptionColor: defaultColors.colorNeutral90,
        borderColor: defaultColors.colorNeutral20,
        titleColor: defaultColors.colorNeutral140,
      },
      equipmentModelsView: {
        drawerParamTextColor: defaultColors.colorNeutral120,
      },
      equipmentStatusTag: {
        statusRunningColor: defaultColors.colorSuccess20,
        statusStoppedColor: defaultColors.colorNeutral30,
        runningTextColor: defaultColors.colorSuccess80,
        stoppedTextColor: defaultColors.colorNeutral110,
      },
      fileUpload: {
        error: {
          borderColor: defaultColors.colorError40,
          backgroundColor: defaultColors.colorError10,
        },
        default: {
          borderColor: defaultColors.colorNeutral50,
          backgroundColor: defaultColors.colorNeutral0,
          clickToUploadTextColor: defaultColors.colorPrimary70,
          dragTextColor: defaultColors.colorNeutral130,
        },
        dragging: {
          borderColor: defaultColors.colorFocus20,
          backgroundColor: defaultColors.colorFocus10,
        },
        disabled: {
          borderColor: defaultColors.colorNeutral50,
          backgroundColor: defaultColors.colorNeutral0,
        },
        uploaded: {
          successTextColor: defaultColors.colorNeutral130,
          borderColor: defaultColors.colorNeutral50,
          backgroundColor: defaultColors.colorNeutral0,
        },
        borderColor: defaultColors.colorNeutral50,
        backgroundColor: defaultColors.colorNeutral0,
        clickToUploadTextColor: defaultColors.colorPrimary70,
        dragTextColor: defaultColors.colorNeutral130,
        iconColor: defaultColors.colorNeutral90,
        iconBackgroundColor: defaultColors.colorNeutral20,
        errorTextColor: defaultColors.colorError90,
        errorHoverTextColor: defaultColors.colorError80,
        errorIconColor: defaultColors.colorError60,
        errorIconBackgroundColor: defaultColors.colorError20,
        draggingTextColor: defaultColors.colorFocus20,
        draggingBorderColor: defaultColors.colorFocus20,
        draggingBackgroundColor: defaultColors.colorFocus10,
        draggingIconColor: defaultColors.colorFocus20,
        disabledTextColor: defaultColors.colorNeutral60,
        disabledBackgroundColor: defaultColors.colorNeutral20,
        disabledIconColor: defaultColors.colorNeutral60,
        supportingTextColor: defaultColors.colorNeutral100,
        selectedFileIconColor: defaultColors.colorSuccess50,
        errorRemoveIconColor: defaultColors.colorError70,
        errorHoverRemoveIconColor: defaultColors.colorError60,
        removeIconColor: defaultColors.colorNeutral100,
        removeHoverIconColor: defaultColors.colorNeutral80,
      },
      filterBar: {
        borderColor: defaultColors.colorNeutral40,
        selectedBorderColor: defaultColors.colorPrimary70,
        backgroundColor: defaultColors.colorNeutral0,
        titleTextColor: defaultColors.colorNeutral130,
        countTextColor: defaultColors.colorNeutral100,
      },
      imagePreview: {
        borderColor: defaultColors.colorNeutral40,
        imageBlurBackgroundColor: hexToRgba(defaultColors.colorNeutral160, 0.75),
        removeIconColor: defaultColors.colorNeutral100,
        removeIconHoverColor: defaultColors.colorPrimary70,
      },
      input: {
        defaultBorderColor: defaultColors.colorNeutral20,
        defaultLabelTextColor: defaultColors.colorNeutral80,
        defaultSupportingTextTextColor: defaultColors.colorNeutral100,
        defaultValueTextColor: defaultColors.colorNeutral150,
        disabledBackgroundColor: defaultColors.colorNeutral30,
        disabledLabelTextColor: defaultColors.colorNeutral60,
        disabledSupportingTextTextColor: defaultColors.colorNeutral60,
        errorLabelTextColor: defaultColors.colorError70,
        errorInputBorderColor: defaultColors.colorError30,
        errorSupportingTextTextColor: defaultColors.colorError70,
        focusedInputBorderColor: defaultColors.colorFocus20,
        focusedLabelTextColor: defaultColors.colorNeutral130,
        focusedSupportingTextTextColor: defaultColors.colorNeutral120,
        hoverBorderColor: defaultColors.colorNeutral50,
        hoverLabelTextColor: defaultColors.colorNeutral130,
        hoverSupportingTextTextColor: defaultColors.colorNeutral120,
        prefixColor: defaultColors.colorNeutral110,
        suffixColor: defaultColors.colorNeutral100,
        prefixAndSuffixDisabledColor: defaultColors.colorNeutral60,
        prefixAndSuffixErrorColor: defaultColors.colorError70,
      },
      inventory: {
        descriptionColor: defaultColors.colorNeutral150,
        fieldTextColor: defaultColors.colorNeutral150,
        fieldTitleColor: defaultColors.colorNeutral120,
      },
      leftPanelImageRight: {
        textNeedHelpColor: defaultColors.colorNeutral110,
        textCopyrightColor: defaultColors.colorNeutral100,
      },
      lightBulb: {
        containerBackgroundColor: defaultColors.colorNeutral20,
        backgroundColor: defaultColors.colorNeutral0,
        borderColor: defaultColors.colorNeutral30,
        textColor: defaultColors.colorNeutral90,
        lastUpdateTextColor: defaultColors.colorNeutral160,
      },
      lineChart: {
        colorStatus: defaultColors.colorNeutral80,
        colorFill: defaultColors.colorFocus20,
        seriesColorPurple: defaultColors.colorChartVector15,
        seriesColorYellow: defaultColors.colorChartVector16,
        seriesColorLightOrange: defaultColors.colorChartVector17,
        seriesColorGreen: defaultColors.colorChartVector18,
        seriesColorOrange: defaultColors.colorChartVector19,
        seriesColorBlue: defaultColors.colorChartVector20,
        seriesColorPink: defaultColors.colorChartVector21,
        seriesColorLightGreen: defaultColors.colorChartVector22,
        seriesColorLightPink: defaultColors.colorChartVector23,
        seriesColorLightBlue: defaultColors.colorChartVector24,
        yAxisColor: defaultColors.colorNeutral90,
      },
      linkTo: {
        neutral: defaultColors.colorNeutral140,
        neutralHover: defaultColors.colorPrimary60,
        primary: defaultColors.colorPrimary70,
        primaryHover: defaultColors.colorPrimary60,
      },
      loader: {
        textColor: defaultColors.colorPrimary70,
      },
      loaderWithText: {
        textColor: defaultColors.colorPrimary70,
      },
      mainNavigation: {
        mainNavigationNavBgColor: defaultColors.colorNeutral0,
        mainNavigationTextColor: defaultColors.colorNeutral80,
        selectedItemTextColor: defaultColors.colorNeutral130,
        mainNavigationIconsColor: defaultColors.colorNeutral120,
        caretDownColor: defaultColors.colorNeutral120,
        borderBottomColor: defaultColors.colorNeutral30,
        menuBorderBottomColor: defaultColors.colorPrimary70,
      },
      mapView: {
        strokeColor: defaultColors.colorNeutral0,
        fillColor: defaultColors.colorNeutral0,
      },
      modalPanel: {
        headerDescriptionTextColor: defaultColors.colorNeutral100,
      },
      moduleHeader: {
        backgroundColor: defaultColors.colorNeutral0,
        buttonBackgroundColor: defaultColors.colorNeutral20,
        iconColor: defaultColors.colorNeutral90,
      },
      navigationTabs: {
        textColor: defaultColors.colorNeutral80,
        selectedItemTextColor: defaultColors.colorNeutral130,
        borderBottomColor: defaultColors.colorPrimary70,
      },
      optionsDropdown: {
        optionsContainerBorder: defaultColors.colorNeutral20,
        optionsContainerBgColor: defaultColors.colorNeutral0,
        optionsContainerBoxShadow: defaultColors.colorNeutral40,
        optionItemBgHoverColor: defaultColors.colorPrimary20,
        optionItemTextColor: defaultColors.colorNeutral110,
        optionItemTextHoverColor: defaultColors.colorPrimary70,
        iconColor: defaultColors.colorNeutral110,
        dividerColor: defaultColors.colorNeutral40,
        dangerBackgroundHoverColor: defaultColors.colorError10,
        dangerTextColor: defaultColors.colorError60,
        dangerTextHoverColor: defaultColors.colorError80,
        disabledBackgroundColor: defaultColors.colorNeutral0,
        disabledDangerTextColor: defaultColors.colorError30,
        disabledDangerTextHoverColor: defaultColors.colorError30,
        disabledOptionTextColor: defaultColors.colorNeutral40,
        disabledOptionTextHoverColor: defaultColors.colorNeutral40,
      },
      optionsSelect: {
        optionsBackgroundHover: defaultColors.colorPrimary20,
        optionsSelectBackground: defaultColors.colorNeutral0,
        optionsSelectBoxShadow: defaultColors.colorNeutral40,
        optionsSelectBorder: defaultColors.colorNeutral20,
        optionTextColor: defaultColors.colorNeutral110,
        optionTextHoverColor: defaultColors.colorPrimary70,
        selectedOptionColor: defaultColors.colorPrimary70,
      },
      pagination: {
        colorPageDefault: defaultColors.colorNeutral0,
        colorEllipsis: defaultColors.colorNeutral140,
      },
      paginationFooter: {
        backgroundColor: defaultColors.colorNeutral0,
        borderColor: defaultColors.colorNeutral30,
      },
      paginationSizeSelector: {
        textColor: defaultColors.colorNeutral110,
        iconColor: defaultColors.colorNeutral100,
        valueColor: defaultColors.colorNeutral60,
        backgroundButtonColor: defaultColors.colorNeutral20,
        currentPageColor: defaultColors.colorNeutral110,
        currentPageSelectedColor: defaultColors.colorNeutral60,
        borderColorHover: defaultColors.colorNeutral60,
      },
      paragraph: {
        default: defaultColors.colorNeutral150,
      },
      paramIncrementor: {
        activeButtonBackgroundColor: defaultColors.colorNeutral60,
        activeButtonBorderColor: defaultColors.colorNeutral60,
        caretColor: defaultColors.colorPrimary70,
        defaultBackgroundColor: defaultColors.colorNeutral0,
        disabledIconColor: defaultColors.colorNeutral60,
        disabledSuffixColor: defaultColors.colorNeutral60,
        focusBackGroundColor: defaultColors.colorNeutral10,
        focusBorderColor: defaultColors.colorNeutral50,
        focusSuffixColor: defaultColors.colorNeutral50,
        hoverButtonBackgroundColor: defaultColors.colorNeutral30,
        hoverButtonBorderColor: defaultColors.colorNeutral50,
        iconColor: defaultColors.colorNeutral140,
        suffixColor: defaultColors.colorNeutral150,
      },
      powerSwitch: {
        textColor: defaultColors.colorNeutral50,
        selectedTextColor: defaultColors.colorAlert140,
        disabledTextColor: defaultColors.colorNeutral50,
      },
      select: {
        backgroundColorSelect: defaultColors.colorNeutral20,
        backgroundColorSelectDisabled: defaultColors.colorNeutral30,
        caretDownColor: defaultColors.colorNeutral140,
        caretDownColorDisabled: defaultColors.colorNeutral80,
        clearIconColor: defaultColors.colorNeutral80,
        clearIconColorHover: defaultColors.colorNeutral140,
        prefixBackgroundColor: defaultColors.colorNeutral20,
        prefixBackgroundColorDisabled: defaultColors.colorNeutral30,
        prefixIconColor: defaultColors.colorNeutral110,
        prefixIconColorDisabled: defaultColors.colorNeutral80,
        textColorPlaceholder: defaultColors.colorNeutral80,
        textColorPlaceholderDisabled: defaultColors.colorNeutral60,
        textColorPlaceholderHover: defaultColors.colorNeutral140,
        textColorSelectedItem: defaultColors.colorNeutral150,
        textColorLabel: defaultColors.colorNeutral80,
        disabledTextColorLabel: defaultColors.colorNeutral60,
        supportingTextTextColor: defaultColors.colorNeutral100,
        supportingTextTextColorHover: defaultColors.colorNeutral120,
        supportingTextColorDisabled: defaultColors.colorNeutral60,
      },
      sideNavigation: {
        borderColor: defaultColors.colorNeutral40,
        focusedBackgroundColor: defaultColors.colorError10,
        focusedTextColor: defaultColors.colorPrimary70,
        hoverBackgroundColor: defaultColors.colorPrimary20,
        hoverTextColor: defaultColors.colorPrimary60,
        itemTextColor: defaultColors.colorNeutral110,
        listTitleTextColor: defaultColors.colorNeutral80,
      },
      speedometer: {
        alarmBackgroundColor: defaultColors.colorError70,
        effectBackgroundColor: defaultColors.colorNeutral0,
        normalBackgroundColor: defaultColors.colorSuccess50,
        offProgressBackgroundColor: defaultColors.colorNeutral100,
        unitTextColor: defaultColors.colorNeutral80,
        ticksBackgroundColor: defaultColors.colorNeutral90,
        ticksValuesTextColor: defaultColors.colorNeutral100,
        titleTextColor: defaultColors.colorNeutral120,
        warningBackgroundColor: defaultColors.colorAlert70,
      },
      statusIndicator: {
        alarmBackgroundColor: defaultColors.colorError70,
        alarmShadowColor: hexToRgba(defaultColors.colorError70, 0.65),
        defaultTextColor: defaultColors.colorNeutral140,
        goodBackgroundColor: defaultColors.colorSuccess40,
        goodShadowColor: hexToRgba(defaultColors.colorSuccess30, 0.65),
        neutralBackgroundColor: defaultColors.colorNeutral60,
        neutralShadowColor: hexToRgba(defaultColors.colorNeutral60, 0.65),
        neutralTextColor: defaultColors.colorNeutral80,
        warningBackgroundColor: defaultColors.colorAlert60,
        warningShadowColor: hexToRgba(defaultColors.colorAlert60, 0.65),
      },
      stepCard: {
        iconBackgroundColor: defaultColors.colorPrimary70,
        imageBackgroundColor: defaultColors.colorNeutral10,
        iconColor: defaultColors.colorNeutral0,
        iconFill: defaultColors.colorNeutral0,
        titleColor: defaultColors.colorNeutral120,
        descriptionColor: defaultColors.colorNeutral100,
        rightIconColor: defaultColors.colorNeutral70,
        rightIconFill: defaultColors.colorNeutral70,
        hoverBoxShadowColor: defaultColors.colorNeutral50,
        clickBoxShadowColor: defaultColors.colorNeutral30,
        borderColor: defaultColors.colorNeutral20,
      },
      switch: {
        checkedBackgroundColorDisabled: defaultColors.colorSuccess30,
        checkedBackgroundColorHover: defaultColors.colorSuccess70,
        defaultUncheckedBackgroundColor: defaultColors.colorNeutral40,
        defaultUncheckedBgColorDisabled: defaultColors.colorNeutral30,
        defaultUncheckedBgColorHover: defaultColors.colorNeutral80,
        disabledTextColor: defaultColors.colorNeutral60,
        supportingTextColor: defaultColors.colorNeutral100,
        supportingTextDisabledColor: defaultColors.colorNeutral60,
        supportingTextHoverColor: defaultColors.colorNeutral120,
        switchColorIcon: defaultColors.colorNeutral0,
        textColor: defaultColors.colorNeutral140,
      },
      switchWithText: {
        backgroundColorHover: defaultColors.colorSuccess70,
        checkedOnTextColor: defaultColors.colorAlert140,
        checkedOffTextColor: defaultColors.colorNeutral140,
        defaultTextColor: defaultColors.colorNeutral50,
        disabledTextColor: defaultColors.colorNeutral60,
        uncheckedBgColorHover: defaultColors.colorNeutral80,
      },
      table: {
        borderColor: defaultColors.colorNeutral0,
        colorThumb: defaultColors.colorNeutral30,
        hoverRowBackgroundColor: defaultColors.colorNeutral10,
      },
      tag: {
        criticalTypeBackgroundColor: defaultColors.colorError10,
        criticalTypeTextColor: defaultColors.colorError70,
        infoTypeBackgroundColor: defaultColors.colorFocus10,
        infoTypeTextColor: defaultColors.colorFocus20,
        neutralTypeBackgroundColor: defaultColors.colorNeutral30,
        neutralTypeTextColor: defaultColors.colorNeutral90,
        successTypeBackgroundColor: defaultColors.colorSuccess20,
        successTypeTextColor: defaultColors.colorSuccess80,
        warningTypeBackgroundColor: defaultColors.colorAlert10,
        warningTypeTextColor: defaultColors.colorAlert80,
      },
      thermometer: {
        alarmBackgroundColor: defaultColors.colorError70,
        alarmTextColor: defaultColors.colorError70,
        minAndMaxValueTextColor: defaultColors.colorNeutral90,
        normalBackgroundColor: defaultColors.colorSuccess50,
        offProgressBackgroundColor: defaultColors.colorNeutral100,
        offThresholdTickBackgroundColor: defaultColors.colorNeutral100,
        tickHighAlarmBackgroundColor: defaultColors.colorError70,
        tickHighWarningBackgroundColor: defaultColors.colorAlert70,
        tickLowAlarmBackgroundColor: defaultColors.colorError70,
        tickLowWarningBackgroundColor: defaultColors.colorAlert70,
        titleTextColor: defaultColors.colorNeutral120,
        warningBackgroundColor: defaultColors.colorAlert70,
        warningTextColor: defaultColors.colorAlert70,
      },
      timeline: {
        colorTitle: defaultColors.colorNeutral150,
        colorThumb: defaultColors.colorNeutral50,
        colorGradient: defaultColors.colorNeutral0,
      },
      timelineItem: {
        borderIcon: defaultColors.colorNeutral40,
        colorIcon: defaultColors.colorNeutral140,
        colorDateHour: defaultColors.colorNeutral90,
        colorText: defaultColors.colorNeutral140,
      },
      title: {
        default: defaultColors.colorNeutral160,
      },
      toast: {
        textColor: defaultColors.colorNeutral150,
        shadowColor: defaultColors.colorNeutral150,
        loadingIconColor: defaultColors.colorPrimary70,
      },
      tooltip: {
        arrowBackgroundColor: defaultColors.colorNeutral160,
        backgroundColor: defaultColors.colorNeutral160,
        lightArrowBackgroundColor: defaultColors.colorNeutral0,
        lightBackgroundColor: defaultColors.colorNeutral0,
        lightTextColor: defaultColors.colorNeutral150,
        shadowColor: defaultColors.colorNeutral30,
        textColor: defaultColors.colorNeutral0,
      },
      settings: {
        panelBackgroundColor: defaultColors.colorNeutral10,
      },
    },
  },
};

/* *************************************************************************************************
**************************************** ANTD THEME ************************************************
************************************************************************************************* */

export const antDTheme: AntDApplicationThemes = {
  default: {
    hashed: false,
    token: {
      colorPrimary: defaultColors.colorPrimary70,
      colorPrimaryHover: defaultColors.colorPrimary60,
    },
    components: {
      Breadcrumb: {
        separatorColor: defaultColors.colorNeutral40,
        separatorMargin: 16,
        fontFamily: defaultFonts.bold,
        colorText: defaultColors.colorNeutral100,
        fontSize: 14,
        lastItemColor: defaultColors.colorNeutral140,
        linkHoverColor: defaultColors.colorPrimary70,
      },
      Checkbox: {
        fontSize: 16,
        colorTextDisabled: defaultColors.colorNeutral60,
        colorBorder: defaultColors.colorNeutral60,
        colorPrimary: defaultColors.colorPrimary70,
        colorPrimaryBorder: defaultColors.colorPrimary70,
        colorBgContainer: defaultColors.colorNeutral0,
      },
      Drawer: {
        colorBgMask: hexToRgba(defaultColors.colorNeutral160, 0.85),
      },
      Input: {
        fontSize: 14,
        colorBgContainer: defaultColors.colorNeutral20,
        colorText: defaultColors.colorNeutral150,
      },
      Modal: {
        colorBgMask: hexToRgba(defaultColors.colorNeutral160, 0.85),
      },
      Pagination: {
        itemActiveBg: defaultColors.colorPrimary70,
        colorPrimary: defaultColors.colorPrimary70,
        colorPrimaryHover: defaultColors.colorNeutral0,
        colorText: defaultColors.colorNeutral140,
      },
      Select: {
        colorBgContainer: defaultColors.colorNeutral20,
        controlHeight: 40,
        controlHeightLG: 50,
        controlHeightSM: 32,
        fontSizeIcon: 16,
        fontSize: 16,
      },
      Switch: {
        colorPrimary: defaultColors.colorSuccess60,
        colorPrimaryHover: defaultColors.colorSuccess70,
      },
      Table: {
        colorBorderSecondary: defaultColors.colorNeutral10,
        colorPrimary: defaultColors.colorPrimary60,
        fontSizeIcon: 8,
        colorTextHeading: defaultColors.colorNeutral110,
        colorText: defaultColors.colorNeutral130,
        fontWeightStrong: 700,
        colorIcon: defaultColors.colorNeutral160,
      },
      Timeline: {
        dotBg: defaultColors.colorNeutral50,
        tailColor: defaultColors.colorNeutral50,
      },
    },
  },
};

export default themes;
