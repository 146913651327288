// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';

// Store
import { getUser } from '../../store/session.store';

// Types
import { User, UserType } from '../../types/global';

// Components
import { AdminPanel } from '../../layout/AdminPanel';
import { PageHeader } from '../../layout/elements/PageHeader';

// Module
import { SettingsModule } from './SettingsModule';

// View
import {
  Container,
  Content,
  PageHeaderContainer,
  PanelContainer,
  RegisteredTrademark,
} from './Settings.style';

function Settings(): JSX.Element | null {
  // Dependencies
  const { t } = useTranslation();
  const navigate: NavigateFunction = useNavigate();

  // Getter
  const user: User | null = getUser();

  // Redirects if user is not found
  if (!user) {
    navigate('/plant-management');
    return null;
  }

  const formatDescription = (description: string): JSX.Element[] => description.split(
    '{{registered}}',
  ).map((part: string, index: number) => (
    <>
      {index > 0 && <RegisteredTrademark>®</RegisteredTrademark>}
      {part}
    </>
  ));

  return user.type === UserType.admin
    ? (
      <AdminPanel>
        <Content>
          <SettingsModule />
          <PanelContainer cols={20}>
            <PageHeaderContainer>
              <PageHeader
                title={t('common.settings')}
                description={formatDescription(t('view.settings.adminDescription'))}
              />
            </PageHeaderContainer>
          </PanelContainer>
        </Content>
      </AdminPanel>
    ) : (
      <Container>
        <Content>
          <SettingsModule />
          <PanelContainer cols={20}>
            <PageHeaderContainer>
              <PageHeader
                title={t('common.settings')}
                description={t('view.settings.customerDescription')}
              />
            </PageHeaderContainer>
          </PanelContainer>
        </Content>
      </Container>
    );
}

export { Settings };
